import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import './index.css'

var timeout = 0;

class Logger extends Component {
	state = {
		entries : []
	}

	addEntry = (event) => {
		const entries = this.state.entries.slice ();

		entries.unshift ({
			time: (new Date()).toString().match(/\d{2}:\d{2}:\d{2}/)[0],
			text: event.text,
			type: event.type
		});

		this.setState ({ entries: entries });

		clearTimeout (timeout);
		timeout = setTimeout (function () {
			dispatcher.dispatchEvent ({
				type: 'logger height has changed'
			});
		}, 1234);
	}

	componentDidMount () {
		dispatcher.addEventListener ('log', this.addEntry);
		dispatcher.addEventListener ('warn', this.addEntry);
		dispatcher.addEventListener ('error', this.addEntry);
		dispatcher.addEventListener ('success', this.addEntry);
	}

	componentWillUnmount () {
		dispatcher.removeEventListener ('log', this.addEntry);
		dispatcher.removeEventListener ('warn', this.addEntry);
		dispatcher.removeEventListener ('error', this.addEntry);
		dispatcher.removeEventListener ('success', this.addEntry);
	}

	render () {
		return (
			<ul className="logger">
				{ this.state.entries.map((entry, index) =>
					<li key={index} className={entry.type}><div className="time">{entry.time}</div>{entry.text}</li>)
				}
			</ul>
		)
	}
}

export default Logger