import React, { Component } from 'react'
import dispatcher from '../../../dispatcher'
import { loadFile } from '../../../utils'
import './index.css'

import shoe from './shoe.png';

const meta = {
	shortDescription: '', longDescription: '', image: ''
};

function updateMeta(input, propertyName) {
	meta[propertyName] = input.value;

	// highlight parent div
	const classList = input.parentElement.classList;
	if (input.value) classList.add('has-data'); else classList.remove('has-data');
}

// hacky, but..
dispatcher.addEventListener('metainfo request', function (event) {
	Object.assign(event, meta);
});


class MetaInfo extends Component {
	constructor(props) {
		super(props);
		this.shrtDescInput = React.createRef();
		this.longDescInput = React.createRef();
		this.imageDiv = React.createRef();
	}

	loadImageData = (event) => {
		meta.image = event.url;
		this.imageDiv.current.classList.add('has-data');
		this.imageDiv.current.style.backgroundImage = 'url(' + event.url + ')';
		this.imageDiv.current.querySelector('input[type="file"]').value = '';
	}

	loadDescriptions = (event) => {
		this.shrtDescInput.current.value = event.shortDescription || '';
		updateMeta(this.shrtDescInput.current, 'shortDescription');

		this.longDescInput.current.value = event.longDescription || '';
		updateMeta(this.longDescInput.current, 'longDescription');
	}

	componentDidMount() {
		dispatcher.addEventListener('shoe image', this.loadImageData);
		dispatcher.addEventListener('shoe descriptions', this.loadDescriptions);
	}

	componentWillUnmount() {
		dispatcher.removeEventListener('shoe image', this.loadImageData);
		dispatcher.removeEventListener('shoe descriptions', this.loadDescriptions);
	}

	render() {
		return (
			<>
				<div className="meta-info"><input autoComplete="off" ref={this.shrtDescInput} onInput={() => updateMeta(this.shrtDescInput.current, 'shortDescription')} placeholder="Short description" /></div>
				<div className="meta-info"><input autoComplete="off" ref={this.longDescInput} onInput={() => updateMeta(this.longDescInput.current, 'longDescription')} placeholder="Long description" /></div>
				<div className="meta-info last" ref={this.imageDiv} style={{ backgroundImage: 'url(' + shoe + ')', height: '90px' }}>
					<div className="button file"><i className="material-icons">file_upload</i><input type="file" accept=".png" onChange={(event) => {
						loadFile(event.nativeEvent.target.files[0], this.loadImageData);
					}}></input></div>
				</div>
			</>
		)
	}
}

export default MetaInfo