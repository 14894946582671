import React, { useEffect, useState } from 'react';

import Logo from '../../ui/header/logo';
import NavBar from '../../ui/header/navbar';
import Logout from '../../ui/header/logout';

import MetaInfo from '../../ui/main/metainfo';
import Title from '../../ui/main/title';
import Radios from '../../ui/main/radios';
import Hierarchy from '../../ui/main/hierarchy';
import Logger from '../../ui/main/logger';
import Model from '../../ui/main/model';
import Transform from '../../ui/main/transform';

import Preloader from '../../ui/preloader';
import PleaseWait from '../../ui/pleasewait';
import UploadForm from '../../ui/uploadform';

import { Scene3D } from '../../scene3d';
import { Preview3D } from '../../preview3d';

import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

export default function Home() {

  const [ initScene, handlerInitScene ] = useState(false)
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }

  useEffect( () => {
    handlerInitScene(true)

  }, [])

  return (

    <div className="studio" >
      <header className="studio-header">
        <Logo />
        <NavBar />
        <Logout
          handleLogout={handleLogout}
        />

      </header>
      <main className="studio-main">
        <div className="col-1">
          <Title icon="info" text="Product Info" />
          <MetaInfo />
          <hr />
          <Title icon="shoe" text="Object" />
          <Radios />
          <Hierarchy
            init={initScene}
          />
          <hr />
          <Title text="Model" style={{ paddingBottom: 0 }} />
          <Model />
        </div>
        <div className="col-2" >
          <Scene3D
            init={initScene}
          />
          <div style={{flex: 'none', maxHeight: '200px', borderTop: '2px solid white'}}>
            <Title icon="description" text="Logger" />
            <Logger />
          </div>
        </div>
        <div className="col-3">
          <Title icon="remove_red_eye" text="Preview" />
          <Preview3D
           />
          <Title icon="cube" text="Transform" style={{ marginBottom: '-15px' }} />
          <Transform />
        </div>
      </main>
      <Preloader />
      <PleaseWait />
      <UploadForm />
    </div>
  );
}
