import { EventDispatcher } from 'three';

const dispatcher = new EventDispatcher();

dispatcher.once = function (type, listener) {
	const handler = function (event) {
		listener (event); dispatcher.removeEventListener (type, handler);
	};
	dispatcher.addEventListener (type, handler);
};

dispatcher.log = function (text) {
	console.log (text);
	dispatcher.dispatchEvent ({
		type: 'log', text: text
	});
};

dispatcher.warn = function (text) {
	console.warn (text);
	dispatcher.dispatchEvent ({
		type: 'warn', text: text
	});
};

dispatcher.error = function (text) {
	console.error (text);
	dispatcher.dispatchEvent ({
		type: 'error', text: text
	});
};

dispatcher.success = function (text) {
	console.log (text);
	dispatcher.dispatchEvent ({
		type: 'success', text: text
	});
};

export default dispatcher;
