import { Vector3 } from 'three'

export function xFlipGeometry (geometry) {
	geometry.scale (-1, 1, 1);

	// reorder vertices
	if (geometry.index) {
		for (let i = 0, il = geometry.index.count; i < il; i += 3) {
			let a = geometry.index.array[i + 1];
			let b = geometry.index.array[i + 2];
			geometry.index.array[i + 1] = b;
			geometry.index.array[i + 2] = a;
		}
	} else {
		const
			positions = geometry.attributes.position,
			normals = geometry.attributes.normal,
			uvs = geometry.attributes.uv,
			vector1 = new Vector3(), vector2 = new Vector3();

		for (let i = 0, il = normals.count; i < il; i += 3) {
			vector1.set (normals.getX(i + 1), normals.getY(i + 1), normals.getZ(i + 1));
			vector2.set (normals.getX(i + 2), normals.getY(i + 2), normals.getZ(i + 2));
			normals.setXYZ (i + 1, vector2.x, vector2.y, vector2.z);
			normals.setXYZ (i + 2, vector1.x, vector1.y, vector1.z);
			vector1.set (positions.getX(i + 1), positions.getY(i + 1), positions.getZ(i + 1));
			vector2.set (positions.getX(i + 2), positions.getY(i + 2), positions.getZ(i + 2));
			positions.setXYZ (i + 1, vector2.x, vector2.y, vector2.z);
			positions.setXYZ (i + 2, vector1.x, vector1.y, vector1.z);
			if (uvs) {
				vector1.set (uvs.getX(i + 1), uvs.getY(i + 1), 0);
				vector2.set (uvs.getX(i + 2), uvs.getY(i + 2), 0);
				uvs.setXY (i + 1, vector2.x, vector2.y);
				uvs.setXY (i + 2, vector1.x, vector1.y);
			}
		}
	}

	return geometry;
}

export function xFlipObject3D (object) {
	object.traverse (function (child) {
		child.position.x *= -1;
		if (child.geometry) {
			child.geometry = xFlipGeometry (child.geometry.clone ());
		}
	});

	return object;
}
