import dispatcher from '../../dispatcher'

import React, {Component} from 'react'
import './index.css'

class Preloader extends Component {

	componentDidMount() {
		dispatcher.addEventListener ('hide preloader', function (event) {
			document.querySelector ('.studio .preloader').style.opacity = 0;
			setTimeout (function () {
				document.querySelector ('.studio .preloader').style.display = 'none';

				if (event.after) dispatcher.dispatchEvent (event.after);
			}, 1000);
		});

		dispatcher.addEventListener ('update preloader', function (event) {
				document.querySelector ('.studio .preloader .progress div').style.width = event.progress + '%';
		});
	}


	render () {
		return (
			<div className="preloader"><div className="progress"><div></div></div></div>
		)
	}
}


// assuming there will be a single preloader


export default Preloader
