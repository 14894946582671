import React, {Component} from 'react'
import './index.css'

import cube from './cube.png';
import info from './info.png';
import shoe from './shoe.png';

class Title extends Component {
	render () {
		return (
			<div className="title"
				{ ...( this.props.style && { style: this.props.style })}
				>
				{this.props.icon === 'shoe'
					? <img src={shoe} alt="" />
					: (this.props.icon === 'cube'
						? <img src={cube} alt="" />
						: (this.props.icon === 'info'
							? <img src={info} alt="" />
							: <i className="material-icons">{this.props.icon}</i>
						)
					)
				}
				<span>{this.props.text}</span>
			</div>
		)
	}
}

export default Title