import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import PrivateRouter from './privateRouter';

import Login from './views/Auth';
import Home from './views/Home';

export const routes = [
  // Here goes all the routes of the app
  { path: '/', exact: true, name: 'Home' },
  { path: '/home', name: 'home', component: Home },
]

function Router() {
  return(
    <HashRouter>
      <Switch>
          <Route path="/login" name="Login Page" component={Login} />
          <PrivateRouter path="/" name="Default Page" component={Home} routes={routes} />
      </Switch>
    </HashRouter>
  )
}

export default Router;
