import React, { Component } from 'react';

class Logout extends Component{

  render () {
    return (
      <div className="logout">
        <span onClick={this.props.handleLogout}>
          Logout
        </span>
      </div>
    )
  }
}

export default Logout
