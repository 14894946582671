import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import {loadFile} from '../../../utils'
import './index.css'

class Model extends Component {
	state = {
		name: 'model_r.glb'
	}

	setModelName = (event) => {
		this.setState ({
			name: event.name + '.glb'
		});
	}

	componentDidMount () {
		dispatcher.addEventListener ('selected', this.setModelName);
		dispatcher.addEventListener ('leg visibility', this.setModelName);
	}

	componentWillUnmount () {
		dispatcher.removeEventListener ('selected', this.setModelName);
		dispatcher.removeEventListener ('leg visibility', this.setModelName);
	}

	render () {
		return (
			<div className="model">
				
				<div className="button file"><i className="material-icons">file_upload</i><input type="file" accept=".glb,.gltf,.obj" onChange={(event) => {
					loadFile (event.nativeEvent.target.files[0], {
						type: 'import model', model: this.state.name
					});
				}}></input></div>
				<div className="name">{this.state.name}</div>
			</div>
		)
	}
}

export default Model
