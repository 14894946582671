import dispatcher from '../dispatcher'

import {
	Cache,
	DoubleSide,
	LoadingManager,
	Mesh,
	MeshStandardMaterial,
	PlaneBufferGeometry
} from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

import { xFlipObject3D } from './flip.js'
import { cacheFile } from './export.js'

import default_shoe_left from './assets/adidas_left.glb'
import default_shoe_right from './assets/adidas_right.glb'
import default_ankle from './assets/ankle_occluder.obj'
import default_leg from './assets/leg_occluder.obj'
import default_foot from './assets/foot.obj'
import default_env_map from './assets/envMap.jpg'


export function replacePhongMaterials (object) {
	object.traverse (function (child) {
		if (child.material && (
			(child.material.type === 'MeshPhongMaterial') ||
			(child.material[0] && (child.material[0].type === 'MeshPhongMaterial'))
		)) {
			child.material = new MeshStandardMaterial ();
		}
	});
	return object;
}


// most of this could prrobably be replaced with loading package.zip

export function initScene (scene, footL, footR, onComplete) {
	Cache.enabled = true;

	const manager = new LoadingManager ();
	manager.onLoad = function () {
		dispatcher.dispatchEvent ({ type: 'hide preloader', after: { type: 'default assets loaded' }});

		const image = Cache.get (default_env_map);
		const canvas = document.createElement ('canvas');
		canvas.width = image.width;
		canvas.height = image.height;
		canvas.getContext ('2d').drawImage (image, 0, 0);
		cacheFile ('envMap.jpg', canvas.toDataURL ('image/jpeg', 0.6).split (';base64,')[1]);

		Cache.enabled = false;
		Cache.clear ();
	};

	dispatcher.dispatchEvent ({ type: 'environment map', url: default_env_map, manager: manager });

	const gltfLoader = new GLTFLoader (manager); var leftShoe, rightShoe;
	const objLoader = new OBJLoader (manager); var ankle, leg, foot;

	var loaded = [0, 0, 0, 0, 0], total = [3150212, 3155936, 1, 1, 1];

	const reportProgress = function (index) {
		return function (event) {
			loaded[index] = event.loaded;
			// anything gzipped has zero event.total :(
			total[index] = total[index] || event.total;
			dispatcher.dispatchEvent ({ type: 'update preloader', progress:
				100 * loaded.reduce(function (a, v) { return a + v; }, 0) /
				       total.reduce(function (a, v) { return a + v; }, 0)
			});
		};
	};

	gltfLoader.load (default_shoe_left, function (result) {
		cacheFile ('model_l.glb', Cache.get (default_shoe_left));
		leftShoe = result.scene;
	}, reportProgress (0));

	gltfLoader.load (default_shoe_right, function (result) {
		cacheFile ('model_r.glb', Cache.get (default_shoe_right));
		rightShoe = result.scene;
	}, reportProgress (1));

	objLoader.load (default_ankle, function (result) {
		ankle = replacePhongMaterials (result);
	}, reportProgress (2));

	objLoader.load (default_leg, function (result) {
		leg = replacePhongMaterials (result);
	}, reportProgress (3));

	objLoader.load (default_foot, function (result) {
		foot = replacePhongMaterials (result);
		// exclude these feet meshes from raycast
		result.traverse (function (mesh) {
			if (mesh.geometry) {
				mesh.raycast = function () {};
			}
		});
	}, reportProgress (4));


	const cloneObj = function (object) {
		const clone = object.clone (true);
		clone.traverse (function (mesh) {
			if (mesh.material) {
				mesh.material = mesh.material.clone ();
			}
		});
		return clone;
	};

	 dispatcher.once ('default assets loaded', function () {
 		foot.position.x = -0.15;
 		scene.add (foot);

 		const footCopy = xFlipObject3D (foot.clone (true));
 		scene.add (footCopy);

 		footCopy.add (footL);
 		foot.add (footR);

 		ankle.name = 'ankle_occ_r';
 		leg.name = 'leg_occ_r';

 		leg.position.set(-0.01, 0.03, -0.215);

 		footR.add (ankle);
 		footR.add (leg);

 		const ankleCopy = xFlipObject3D (cloneObj (ankle)); ankleCopy.name = 'ankle_occ_l';
 		footL.add (ankleCopy);
 		const legCopy = xFlipObject3D (cloneObj (leg)); legCopy.name = 'leg_occ_l';
 		footL.add (legCopy);

 		leftShoe.name = 'glb';
 		rightShoe.name = 'glb';
 		footR.add (rightShoe);
 		// whats with the slowdown?
 		footL.add (leftShoe);
 		footCopy.visible = false;

 		// create plane occluders
 		const planeGeometry = new PlaneBufferGeometry (0.2, 0.2);
 		const planeMaterial = new MeshStandardMaterial ({ side: DoubleSide });
 		const planeR = new Mesh (planeGeometry, planeMaterial);
 		planeR.position.set (0, 0.1, -0.3);
 		planeR.rotation.x = -69 * Math.PI / 180;
 		planeR.name = 'plane_occ_r';
 		footR.add (planeR);

 		const planeL = planeR.clone ();
 		planeL.material = planeR.material.clone ();
 		planeL.name = 'plane_occ_l';
 		footL.add (planeL);


 		onComplete ();

 	});
}
