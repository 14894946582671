import React from 'react';
import { Redirect , Route } from 'react-router-dom';

import { useAppContext } from "./libs/contextLib";

export default function PrivateRouter({ component: Component, ...rest}) {

  const { isAuthenticated } = useAppContext();

  return (
    <Route {...rest}
          render={props => (
      isAuthenticated
            ? <Component {...props} {...rest}/>
            : <Redirect to={{pathname: '/login', state: { from: props.location } }
          } />
    )} />
  )
}
