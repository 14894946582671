import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Logo from '../../ui/header/logo';
import { Auth } from "aws-amplify";
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
import { Redirect } from 'react-router-dom';

function PasswordValidationHelper(props) {
  return <div className="password-validation-popup">
    <div className="arrow"></div>
    <div className="lines">
      <div><span className={"" + props.has8}></span>Minimum 8 characters</div>
      <div><span className={"" + props.hasNumber}></span>At least one number</div>
      <div><span className={"" + props.hasSpecial}></span>At least one special character</div>
      <div><span className={"" + props.hasUppercase}></span>At least one uppercase character</div>
      <div><span className={"" + props.hasLowercase}></span>At least one lowercase character</div>
    </div>
  </div>;
}

function hasEightCharacters(pass) { return pass.length >= 8 }
function hasOneNumber(pass) { return !!pass.match(/\d/) }
function hasOneSpecialCharacter(pass) { return !!pass.match(/[!-\/:-@\[-`{-~]/) }
function hasOneUppercaseCharacter(pass) { return !!pass.match(/[A-Z]/) }
function hasOneLowercaseCharacter(pass) { return !!pass.match(/[a-z]/) }


export default function Login() {

  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [usernameSent, setUsernameSent] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    verifyPassword: ""
  });

  const [newUserFields, handleNewUserField] = useFormFields({
    newPassword: "",
    newPasswordVerify: ""
  })

  const [forgotPasswordUserField, handleForgotPasswordUserField] = useFormFields({
    email: "",
    code: "",
    newForgotPassword: "",
    newForgotPasswordVerify: ""
  })

  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateForgotPasswordForm() {
    return forgotPasswordUserField.email.length > 0
  }

  function validateForgotPasswordConfirmationForm() {
    return forgotPasswordUserField.email.length > 0 && forgotPasswordUserField.code.length > 0 && forgotPasswordUserField.newForgotPassword.length > 0
      && forgotPasswordUserField.newForgotPassword === forgotPasswordUserField.newForgotPasswordVerify
      && hasEightCharacters(forgotPasswordUserField.newForgotPassword)
      && hasOneNumber(forgotPasswordUserField.newForgotPassword)
      && hasOneSpecialCharacter(forgotPasswordUserField.newForgotPassword)
      && hasOneUppercaseCharacter(forgotPasswordUserField.newForgotPassword)
      && hasOneLowercaseCharacter(forgotPasswordUserField.newForgotPassword)
  }

  function handleNewPasswordRequired() {
    setNewPasswordRequired(true)
  }

  function handleUsernameSent() {
    Auth.forgotPassword(forgotPasswordUserField.email)
      .then(data => {
        console.log(data)
        setUsernameSent(true)
      })
      .catch(err => {
        setErrorMessage(err.message)
        console.log(err)
      });
  }

  function handleUsernameCodeNewPasswordSent() {
    Auth.forgotPasswordSubmit(forgotPasswordUserField.email, forgotPasswordUserField.code, forgotPasswordUserField.newForgotPassword)
      .then(data => {
        console.log(data)
        Auth.signIn(forgotPasswordUserField.email, forgotPasswordUserField.newForgotPassword).then(cognitoUser => {
          userHasAuthenticated(true);
          
          window.location = window.location.href.slice(0, window.location.length - 5)

          var timeout = setTimeout (function () {
            window.location.reload()
          }, 1500); 
        })
      })
      .catch(err => {
        setErrorMessage(err.message)
        console.log(err)
      });
  }

  function handleForgotPassword(email) {
    setErrorMessage('')
    setForgotPassword(!forgotPassword)
  }

  function validateFormVerifyPassword() {
    return newUserFields.newPassword === newUserFields.newPasswordVerify
      && hasEightCharacters(newUserFields.newPassword)
      && hasOneNumber(newUserFields.newPassword)
      && hasOneSpecialCharacter(newUserFields.newPassword)
      && hasOneUppercaseCharacter(newUserFields.newPassword)
      && hasOneLowercaseCharacter(newUserFields.newPassword)
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await Auth.signIn(fields.email, fields.password).then(async cognitoUser => {

        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED" && newUserFields.newPassword !== "") {
          await Auth.completeNewPassword(cognitoUser, newUserFields.newPassword)

          userHasAuthenticated(true);
          window.location = window.location.href.slice(0, window.location.length - 5)
          var timeout = setTimeout (function () {
            window.location.reload()
          }, 1500); 

        } else if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          return handleNewPasswordRequired()
        } else {
          userHasAuthenticated(true);

          window.location = window.location.href.slice(0, window.location.length - 5)

          var timeout = setTimeout (function () {
            window.location.reload()
          }, 1500); 
        }
      })

    } catch (err) {
      setErrorMessage(err.message);
      console.log(err);
    }
  }

  return (
    <div className="Login">
      <Logo />
      {
        !newPasswordRequired ?
          (
            !forgotPassword ?
              <form onSubmit={handleSubmit} className="login-form">
                <h4>Log In</h4>
                <FormGroup controlId="email" bssize="large">
                  <FormLabel>Email</FormLabel>
                  <FormControl
                    autoFocus
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                  />
                </FormGroup>
                <FormGroup controlId="password" bssize="large">
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    value={fields.password}
                    onChange={handleFieldChange}
                    type="password"
                  />
                </FormGroup>
                <a className="forgot-pass" href="#/login" onClick={handleForgotPassword}>
                  Forgot password ?
                </a>
                {
                  errorMessage &&
                  <p className="red">
                    {errorMessage}
                  </p>
                }
                <Button block style={{ marginTop: 55 }} bssize="large" disabled={!validateForm()} type="submit">
                  Login
            </Button>
              </form>
              :
              (
                !usernameSent ?
                  <form onSubmit={handleUsernameSent} className="login-form">
                    <h4>Forgot your password?</h4>
                    <p>We’ll send the instructions to recover your password via email</p>
                    <FormGroup controlId="email" bssize="large">
                      <FormLabel>Email</FormLabel>
                      <FormControl
                        autoFocus
                        type="email"
                        value={forgotPasswordUserField.email}
                        onChange={handleForgotPasswordUserField}
                      />
                    </FormGroup>
                    {
                      errorMessage &&
                      <p className="red">
                        {errorMessage}
                      </p>
                    }
                    <Button block style={{ marginTop: 32 }} bssize="large" disabled={!validateForgotPasswordForm()} type="submit">
                      Reset password
            </Button>
                  </form>
                  :
                  <form onSubmit={handleUsernameCodeNewPasswordSent} className="login-form password-validation">
                    <h4>Reset password</h4>
                    <p>Enter the code that was sent to {forgotPasswordUserField.email} and create a new password</p>
                    <FormGroup controlId="code" bssize="large">
                      <FormLabel>Code</FormLabel>
                      <FormControl
                        value={forgotPasswordUserField.code}
                        onChange={handleForgotPasswordUserField}
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup controlId="newForgotPassword" bssize="large">
                      <FormLabel>New password<PasswordValidationHelper
                        has8={hasEightCharacters(forgotPasswordUserField.newForgotPassword)}
                        hasNumber={hasOneNumber(forgotPasswordUserField.newForgotPassword)}
                        hasSpecial={hasOneSpecialCharacter(forgotPasswordUserField.newForgotPassword)}
                        hasUppercase={hasOneUppercaseCharacter(forgotPasswordUserField.newForgotPassword)}
                        hasLowercase={hasOneLowercaseCharacter(forgotPasswordUserField.newForgotPassword)}
                        /></FormLabel>
                      <FormControl
                        value={forgotPasswordUserField.newForgotPassword}
                        onChange={handleForgotPasswordUserField}
                        type="password"
                        onFocus={(e) => {e.target.closest(".form-group").classList.add("show-helper")}}
                        onBlur={(e) => {e.target.closest(".form-group").classList.remove("show-helper")}}
                      />
                    </FormGroup>
                    <FormGroup controlId="newForgotPasswordVerify" bssize="large">
                      <FormLabel>Verify password</FormLabel>
                      <FormControl
                        value={forgotPasswordUserField.newForgotPasswordVerify}
                        onChange={handleForgotPasswordUserField}
                        type="password"
                      />
                    </FormGroup>
                    {
                      errorMessage &&
                      <p className="red">
                        {errorMessage}
                      </p>
                    }
                    <Button block style={{ marginTop: 32 }} bssize="large" disabled={!validateForgotPasswordConfirmationForm()} type="submit">
                      Update Password
            </Button>
                  </form>
              )

          )
          :
          <form onSubmit={handleSubmit} className="login-form password-validation">
            <h4>Update password</h4>
            <p>Create a new password in order to login to the platform.</p>
            <FormGroup controlId="newPassword" bssize="large">
              <FormLabel>New password<PasswordValidationHelper
                has8={hasEightCharacters(newUserFields.newPassword)}
                hasNumber={hasOneNumber(newUserFields.newPassword)}
                hasSpecial={hasOneSpecialCharacter(newUserFields.newPassword)}
                hasUppercase={hasOneUppercaseCharacter(newUserFields.newPassword)}
                hasLowercase={hasOneLowercaseCharacter(newUserFields.newPassword)}
                /></FormLabel>
              <FormControl
                autoFocus
                type="password"
                value={newUserFields.newPassword}
                onChange={handleNewUserField}
                onFocus={(e) => {e.target.closest(".form-group").classList.add("show-helper")}}
                onBlur={(e) => {e.target.closest(".form-group").classList.remove("show-helper")}}
              />
            </FormGroup>
            <FormGroup controlId="newPasswordVerify" bssize="large">
              <FormLabel>Verify password</FormLabel>
              <FormControl
                value={newUserFields.newPasswordVerify}
                onChange={handleNewUserField}
                type="password"
              />
            </FormGroup>
            {
              errorMessage &&
              <p className="red">
                {errorMessage}
              </p>
            }
            <Button block style={{ marginTop: 32, marginBottom: 18 }} bssize="large" disabled={!validateFormVerifyPassword()} type="submit">
              Set new  password
          </Button>
          </form>
      }
    </div>
  );
}
