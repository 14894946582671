import dispatcher from '../dispatcher'

import {
  Group,
  LinearFilter,
  PerspectiveCamera,
  Scene,
  ShaderMaterial,
  sRGBEncoding,
  Texture,
  WebGLRenderer,
} from 'three'

// TODO: import from an utility file
// Get gbl objects from scene3d
import { getSceneInfo } from '../scene3d'

import React, { Component } from 'react'
import Dropdown from 'react-dropdown'
import './index.css'

import iconRecord from './record_icon.png'
import iconExpand from './expand_icon.png'
import iconMinimize from './minimise_icon.png'

import MP4 from './mp4'

const sceneInfo = getSceneInfo()

// TODO find out how / when to switch this off

const videos = [
  { value: 0, label: 'Image 1', url: '20200207_b_1581081345535.json' },
  { value: 1, label: 'Video 1', url: '20200207_a_1581066327227.json' },
  { value: 2, label: 'Video 2', url: '20220810_a_vyk_studio.json' },
  { value: 3, label: 'Video 3', url: '20220810_b_vyk_studio.json' },
]

const pixelRatio = window.devicePixelRatio || 1

const rendererPreview = new WebGLRenderer({
  antialias: window.location.hostname !== 'localhost',
  preserveDrawingBuffer: true,
})
rendererPreview.autoClear = false
rendererPreview.setPixelRatio(pixelRatio)
rendererPreview.outputEncoding = sRGBEncoding
rendererPreview.gammaFactor = 0.5
rendererPreview.setSize(175, 311)

var wide = false,
  wideChanged = false

const scenePreview = new Scene(),
  cameraPreview = new PerspectiveCamera(42, 175 / 311, 0.001, 100.0)

const leg = new Group()
leg.matrixAutoUpdate = false
leg.matrixWorldNeedsUpdate = false
scenePreview.add(leg)

dispatcher.addEventListener('environment map loaded', function (event) {
  scenePreview.environment = event.makeFor(rendererPreview)
})

const imageTexture = new Texture(),
  maskTexture = new Texture(),
  frames = []
var currentFrame = 0,
  lastFrameTime,
  playPreviewFrame,
  currentLoading = 0

imageTexture.encoding = sRGBEncoding
imageTexture.minFilter = LinearFilter
imageTexture.generateMipmaps = false

maskTexture.minFilter = LinearFilter
maskTexture.generateMipmaps = false

const debugOccluders = false
const resolution = { x: 175 * pixelRatio, y: 311 * pixelRatio }

function makeOccluderMaterial(threshold) {
  return new ShaderMaterial({
    vertexShader: `
			void main () {
				gl_Position = projectionMatrix * modelViewMatrix * vec4 (position, 1.0);
			}
		`,
    fragmentShader: debugOccluders
      ? `
			uniform sampler2D maskTexture;
			uniform vec2 resolution;
			void main () {
				vec2 coordinates = gl_FragCoord.xy / resolution;
				gl_FragColor = texture2D (maskTexture, coordinates);
			}
		`
      : `
			uniform sampler2D imageTexture;
			uniform sampler2D maskTexture;
			uniform vec2 resolution;
			void main () {
				vec2 coordinates = gl_FragCoord.xy / resolution;
				vec4 mask = texture2D (maskTexture, coordinates);
				if (mask.x + mask.y + mask.z < ${threshold}) discard; else {
					gl_FragColor = texture2D (imageTexture, coordinates);
				}
			}
		`,
    uniforms: {
      imageTexture: { value: imageTexture },
      maskTexture: { value: maskTexture },
      resolution: { value: resolution },
    },
  })
}

function assignOccluderMaterials(material) {
  return function (name) {
    var occluder =
      sceneInfo.footL.parent.getObjectByName(name) ||
      sceneInfo.footR.parent.getObjectByName(name)
    if (occluder) {
      occluder.traverse(function (child) {
        if (child.material) {
          child.material = material
        }
      })
    }
  }
}

const occluderMaterials = {
  /*
	- All Green, Yellow and White parts of the camera image have to be orthographically projected onto the ankle occluder.
	- Both Yellow and White part onto the leg occluder.
	- Only the White part onto the plane occluder.
	*/
  ancle: assignOccluderMaterials(makeOccluderMaterial(0.5)),
  leg: assignOccluderMaterials(makeOccluderMaterial(1.5)),
  plane: assignOccluderMaterials(makeOccluderMaterial(2.5)),
}

function saveParentsAndMaterials(child) {
  if (child.material) {
    child.userData.material = child.material
  }
  child.userData.parent = child.parent
}

function restoreParents(child) {
  if (child.userData.parent) {
    child.parent = child.userData.parent
  }
}

function restoreParentsAndMaterials(child) {
  if (child.userData.material) {
    child.material = child.userData.material
    delete child.userData.material
  }
  if (child.userData.parent) {
    child.parent = child.userData.parent
    delete child.userData.parent
  }
}

function renderLeg(pose, original, originalOther) {
  var mirrored = false

  leg.children.length = 0
  if (original.parent) {
    if (original.parent.visible) {
      original.parent.children.forEach(function (child) {
        if (
          child.name &&
          child.name.charCodeAt(child.name.length - 2) === 95 /* _ */
        ) {
          leg.children.push(child)
          child.parent = leg
        }
      })
    } else {
      originalOther.parent.children.forEach(function (child) {
        if (
          child.name &&
          child.name.charCodeAt(child.name.length - 2) === 95 /* _ */
        ) {
          leg.children.push(child)
          child.parent = leg
        }
      })
      mirrored = true
    }
  }

  leg.matrix.set(
    pose[0],
    pose[4],
    pose[8],
    pose[12],
    pose[1],
    pose[5],
    pose[9],
    pose[13],
    pose[2],
    pose[6],
    pose[10],
    pose[14],
    pose[3],
    pose[7],
    pose[11],
    pose[15]
  )

  if (mirrored) {
    // use matrixWorld as temp. scale matrix
    leg.matrixWorld.makeScale(-1, 1, 1)
    leg.matrix.multiply(leg.matrixWorld)
  }

  leg.matrixWorld.copy(leg.matrix)

  leg.updateMatrixWorld(true)

  scenePreview.traverse(function (object) {
    if (object.userData.mixer) object.userData.mixer.setTime(Date.now() * 1e-3)
  })

  rendererPreview.render(scenePreview, cameraPreview)
}

var recorder

function playPreview() {
  cancelAnimationFrame(playPreviewFrame)

  if (!sceneInfo.footL.parent) {
    playPreviewFrame = requestAnimationFrame(playPreview)
    return
  }

  var renderedSomething = false

  // if there is anything to render ?
  if (frames.length)
    if (rendererPreview.domElement.parentElement) {
      if (Date.now() - lastFrameTime > 40) {
        currentFrame++
        lastFrameTime = Date.now()
      }

      // loadPreviewVideo() stores the frames final length in 1st frame
      // this allows us to wait for all of the frames to load before looping back to frame 0 here
      if (frames.length < frames[0].length) {
        currentFrame = Math.min(currentFrame, frames.length - 1)
      } else {
        currentFrame = currentFrame % frames.length
      }

      // do we need to render different frame ?
      if (imageTexture.image !== frames[currentFrame].image) {
        imageTexture.image = frames[currentFrame].image
        imageTexture.needsUpdate = true

        maskTexture.image = frames[currentFrame].mask
        maskTexture.needsUpdate = true

        // is it of different size ?
        const s = wide ? 2 : 1
        const h = Math.floor(
          (imageTexture.image.height * 175) / imageTexture.image.width
        )
        if (wideChanged) {
          wideChanged = false
          rendererPreview.setSize(175 * s, h * s)
          cameraPreview.aspect = 175 / h
          cameraPreview.updateProjectionMatrix()
          resolution.x = 175 * s * pixelRatio
          resolution.y = h * s * pixelRatio
        }

        rendererPreview.clear()

        sceneInfo.footL.parent.traverse(saveParentsAndMaterials)
        sceneInfo.footR.parent.traverse(saveParentsAndMaterials)
        ;['ankle_occ_l', 'ankle_occ_r'].forEach(occluderMaterials.ancle)
        ;['leg_occ_l', 'leg_occ_r'].forEach(occluderMaterials.leg)
        ;['plane_occ_l', 'plane_occ_r'].forEach(occluderMaterials.plane)

        scenePreview.background = imageTexture
        renderLeg(
          frames[currentFrame].poses[0],
          sceneInfo.footL,
          sceneInfo.footR
        )

        sceneInfo.footL.parent.traverse(restoreParents)
        sceneInfo.footR.parent.traverse(restoreParents)

        scenePreview.background = null
        renderLeg(
          frames[currentFrame].poses[1],
          sceneInfo.footR,
          sceneInfo.footL
        )

        sceneInfo.footL.parent.traverse(restoreParentsAndMaterials)
        sceneInfo.footL.parent.updateMatrixWorld(true)

        sceneInfo.footR.parent.traverse(restoreParentsAndMaterials)
        sceneInfo.footR.parent.updateMatrixWorld(true)

        renderedSomething = true
      }
    }

  if (recorder && renderedSomething) {
    recorder
      .appendImageDataUrl(rendererPreview.domElement.toDataURL('image/jpeg'))
      .then(function () {
        if (recorder.frameCount === frames.length) {
          // save the recording
          recorder.finalize().then(function (file) {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(
              new Blob([file.buffer], { type: 'application/octet-stream' })
            )
            link.download = 'preview.mp4'
            link.dispatchEvent(new MouseEvent('click'))
          })

          stopRecording()
        }

        playPreviewFrame = requestAnimationFrame(playPreview)
      })
  } else {
    playPreviewFrame = requestAnimationFrame(playPreview)
  }
}

function loadPreviewVideo(index) {
  stopRecording()

  currentLoading = currentLoading + 1

  const loading = currentLoading
  const request = new XMLHttpRequest()
  request.onload = function () {
    const data = JSON.parse(request.responseText)

    // 1 change camera params
    cameraPreview.fov =
      2 * Math.atan2(data.cam_im_h / 2, data.focal_length) * (180 / Math.PI)
    cameraPreview.updateProjectionMatrix()

    // 2 load frames
    const framePath = 'preview/' + data.imgDir + '/'

    frames.length = 0
    const loadNextFrame = function () {
      const index = frames.length
      const image = new Image()
      image.onload = function () {
        const mask = new Image()
        mask.onload = function () {
          // interrupted?
          if (loading !== currentLoading) return

          // no, keep going
          const frame = {
            image: image,
            mask: mask,
            poses: [
              data.leftFootInfo.pose_ogl[index],
              data.rightFootInfo.pose_ogl[index],
            ],
          }

          if (index === 0) {
            frame.length = 0
            while (data.leftFootInfo.pose_ogl[frame.length]) frame.length++
            lastFrameTime = Date.now()
          }

          frames.push(frame)

          if (data.leftFootInfo.pose_ogl[frames.length]) {
            loadNextFrame()
          }
        }
        mask.src = framePath + data.segName + index + '.jpg'
      }
      image.src = framePath + data.imageName + index + '.jpg'
    }

    loadNextFrame()
  }
  request.open('GET', 'preview/' + videos[index].url)
  request.send()
}

function changePreviewWidth() {
  stopRecording()

  wide = !wide
  wideChanged = true

  document.querySelector('.studio').classList.toggle('wide-preview')

  lastFrameTime = Date.now() - 50
  playPreview()

  dispatcher.dispatchEvent({ type: 'preview width has changed' })
}

function stopRecording() {
  recorder = undefined
  dispatcher.dispatchEvent({ type: 'recording was stopped' })
}

export class Preview3D extends Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
    dispatcher.addEventListener('recording was stopped', () => {
      this.wrapper.current
        ? this.wrapper.current.classList.remove('recording')
        : window.location.reload()
    })
  }
  componentDidMount() {
    this.wrapper.current.insertBefore(
      rendererPreview.domElement,
      this.wrapper.current.firstChild
    )
    loadPreviewVideo(0)
    playPreview()
  }
  record = () => {
    this.wrapper.current.classList.add('recording')
    recorder = new MP4()
  }
  render() {
    return (
      <div className='preview-3d' ref={this.wrapper}>
        <img
          alt=''
          className='image-button expand'
          src={iconExpand}
          onClick={changePreviewWidth}
        />
        <img
          alt=''
          className='image-button minimize'
          src={iconMinimize}
          onClick={changePreviewWidth}
        />
        <Dropdown
          options={videos}
          onChange={function (option) {
            loadPreviewVideo(option.value)
          }}
          value={videos[0]}
        />
      </div>
    )
  }
}
