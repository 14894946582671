import React, {Component} from 'react'
import './index.css'

import logo from './logo.png';

class Logo extends Component {
	render () {
		return (
			<div className="logo">
				<img src={logo} alt="" />
				<span>Sneaker studio</span>
			</div>
		)
	}
}

export default Logo