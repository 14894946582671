import dispatcher from '../dispatcher'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { GammaEncoding, PMREMGenerator, TextureLoader, UnsignedByteType } from 'three'
import { cacheFile, uncacheFile } from './export.js'


function dispatchTexture (texture) {
	dispatcher.dispatchEvent ({
		type: 'environment map loaded',
		makeFor: function (renderer) {
			const pmremGenerator = new PMREMGenerator (renderer);
			pmremGenerator.compileEquirectangularShader ();
			const finalTexture = pmremGenerator.fromEquirectangular (texture).texture;
			finalTexture.encoding = GammaEncoding;
			pmremGenerator.dispose();
			return finalTexture;
		}
	});
}


function loadLDR (url, manager) {
	(new TextureLoader (manager)).load (url, dispatchTexture);
}


function loadHDR (url, manager) {
	(new RGBELoader (manager)).setDataType (UnsignedByteType).load (url, dispatchTexture);
}


dispatcher.addEventListener ('environment map', function (event) {

	if (/(jpe?g?)$/i.test (event.name || event.url)) {

		if (event.name) dispatcher.log ('loaded JPEG environment map: ' + event.name);

		loadLDR (event.url, event.manager); if (event.url.indexOf (';base64,') > -1) {
			uncacheFile ('envMap.hdr'); cacheFile ('envMap.jpg', event.url.split (';base64,')[1]);
		}

	} else

	if (/hdr$/i.test (event.name)) {

		dispatcher.log ('loaded HDR environment map: ' + event.name);

		loadHDR (event.url, event.manager); if (event.url.indexOf (';base64,') > -1) {
			uncacheFile ('envMap.jpg'); cacheFile ('envMap.hdr', event.url.split (';base64,')[1]);
		}

	} else

	dispatcher.error ('could not load environment map from ' + event.name + ' (only JPEG or HDR are supported)');

});


