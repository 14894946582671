import React, { useState, useEffect } from 'react';
import Router from './router';

import { AppContext } from "./libs/contextLib";
import './App.css';

import { Auth } from "aws-amplify";


function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    setIsAuthenticating(false);
  }


  return (
    !isAuthenticating &&
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <Router/>
    </AppContext.Provider>
  );
}

export default App;
