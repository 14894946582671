import dispatcher from '../../dispatcher'
import { getAPIUserNameAndKey, exportSceneFor } from '../../utils';
import { toCanvas } from 'qrcode';
import React, {Component} from 'react'
import './index.css'

import spinner from '../pleasewait/spinner.png';

const HIDDEN = 0, PENDING = 1, UPLOADING = 2, DONE = 3;

class UploadForm extends Component {
	state = {
		upload: HIDDEN
	}
	constructor() {
		super();
		this.canvasA = React.createRef(null);
		this.canvasB = React.createRef(null);
		dispatcher.addEventListener ('upload form', () => {
			this.setState ({ upload: UPLOADING });
			
			exportSceneFor ('android', (androidBlob) => {
				exportSceneFor ('ios3', (iosBlob) => {
					this.upload (androidBlob, iosBlob);
				});
			});
		});
	}
	upload (androidBlob, iosBlob) {
		getAPIUserNameAndKey().then(api => {
			const request = new XMLHttpRequest ();
			request.responseType = 'text';

			const data = new FormData ();
			data.append ('token', api.key);
			data.append ('ioszip', iosBlob, 'ios.zip');
			data.append ('androidzip', androidBlob, 'android.zip');

			request.addEventListener ('load', () => {
				const urls = request.responseText.match(/"https[^"]+/g).map(function(s) { return s.substr(1) });
				const options = { color: { light: '00000000', dark: 'FFFFFFFF' }};
				if (this.state.upload !== HIDDEN) this.setState({ upload: DONE });
				setTimeout (() => {
					toCanvas (this.canvasA.current, urls[0], options);
					toCanvas (this.canvasB.current, urls[1], options);
				}, 9);
			});
	
			request.addEventListener ('error', (error) => {
				console.error (error);
				if (this.state.upload !== HIDDEN) this.setState({ upload: PENDING });
			});
	
			request.open ('POST', 'https://footfitting.vyking.io/cgi-bin/ul_ar_asset.p');
			request.send (data);

			this.setState({ upload: UPLOADING });
		}, dispatcher.error);
	}
	render () {
		return (
			<div className="uploadform" style={{ display: this.state.upload === HIDDEN ? 'none' : 'block' }}>
				<div className="inner"><h1>Live preview</h1>
					{(this.state.upload === UPLOADING) && <>
						<br />
						<img className="spinner" src={spinner} alt="" />
						<br /><br />
						Please wait while your QR code is being generated
					</>}
					{(this.state.upload === DONE) && <>
						<span className="toggle" onClick={e => e.target.closest('.inner').classList.toggle('toggled')}>
							<span className="a">IOS/ANDROID</span>
							<svg style={{marginBottom: -2}} width="30" height="13" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect x="7" y="7" width="10" height="10" rx="5" fill="#FF6800"/>
								<rect x="1.5" y="1.5" width="37" height="21" rx="10.5" stroke="white" strokeWidth="3"/>
							</svg>
							<span className="b">WEB</span>
						</span><br />
						<canvas className="ca" ref={this.canvasA} /><canvas className="cb" ref={this.canvasB} /><br />
						Scan with Vyking&amp;s SneakerKit mobile app<br />
						<span className="orange">This QR code will expire in 24 hours.</span>
					</>}
					<div className="x" onClick={() => this.setState({ upload: HIDDEN })}>X</div>
				</div>
			</div>
		)
	}
}

export default UploadForm;
