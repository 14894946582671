import React, {Component} from 'react'
import {autoPosition, exportSceneFor, loadFile, getAPIUserNameAndKey, setAPIUserNameAndKey, uploadForm} from '../../../utils'
import './index.css'

class NavBar extends Component {
	state = {
		username: '', key: ''
	}
	constructor() {
		super();
		this.apiMenu = React.createRef(null);
		this.iosMenu = React.createRef(null);
		getAPIUserNameAndKey().then(api => {
			this.setState({
				username: api.username, key: api.key
			});
		}, () => {});
	}
	render () {
		return (
			<div className="navbar">
				<div onClick={() => this.iosMenu.current.classList.toggle('hidden')} style={{ paddingTop: '14px', position: 'relative' }}>Export iOS <span className="material-icons" style={{ transform: 'translateY(4.5px)' }}>expand_more</span>
					<div className="hidden ios-menu" ref={this.iosMenu}>
						<div onClick={() => exportSceneFor ('ios2')}>Google Converter</div>
						<div onClick={() => exportSceneFor ('ios1')}>Apple Converter</div>
						<div onClick={() => exportSceneFor ('ios3')}>ThreeJS Converter</div>
					</div>
				</div>
				<div onClick={() => exportSceneFor ('android')}>Export Android</div>
				<div className="file">Environment Lighting<input type="file" accept=".hdr,.jpe,.jpeg,.jpg" onChange={(event) => {
					loadFile (event.nativeEvent.target.files[0], { type: 'environment map' });
				}}></input></div>
				<div className="file">Import Android<input type="file" accept=".glb,.gltf,.obj,.json,.zip,.png,.hdr,.jpe,.jpeg,.jpg" onChange={(event) => {
					loadFile (event.nativeEvent.target.files[0], { type: 'import something' });
				}}></input></div>
				<div onClick={autoPosition}>Auto-Position</div>
				<div onClick={uploadForm}>Live Preview</div>
				<div onClick={(e) => {
					const isSaveButton = (e.target.type === 'button');
					const isInAPIMenu = !!e.target.closest('div.api-menu');
					if ((!isInAPIMenu) || isSaveButton) {
						this.apiMenu.current.classList.toggle('hidden');
					}}} style={{ borderLeft: '2px solid white', flexGrow: 0, width: '40px', position: 'relative' }}><span className="material-icons">settings</span>
					<div className="hidden api-menu" ref={this.apiMenu}>
						<div>
							<input placeholder="api_username" defaultValue={this.state.username}></input><br />
							<input placeholder="api_key" defaultValue={this.state.key}></input><br />
							<input type="button" value="save" onClick={() => {
								const inputs = this.apiMenu.current.querySelectorAll('input');
								setAPIUserNameAndKey(inputs[0].value, inputs[1].value);
							}}></input>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default NavBar
