import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import './index.css'

export class Input extends Component {
	constructor (props) {
		super (props);
		this.numberInput = React.createRef ();

		document.addEventListener('keydown', (event) => {
			if (this.numberInput.current && (event.key === 'Shift')) {
				this.numberInput.current.step = 10 * this.props.step;
			}
		});

		document.addEventListener('keyup', (event) => {
			if (this.numberInput.current) {
				this.numberInput.current.step = this.props.step;
			}
		});
	}
	round (value) {
		if (!this.numberInput.current) return value || 0;
		const n = Math.round( 1 / parseFloat(this.props.step) );
		const v = Math.round( parseFloat(value !== undefined ? value : this.numberInput.current.value) * n ) / n;
		if (value !== undefined) {
			this.numberInput.current.setAttribute('value', v);
			this.numberInput.current.value = v;
		}
		return parseFloat(v);
	}
	dispatch () {
		dispatcher.dispatchEvent({
			type: this.props.event,
			label: this.props.label,
			value: this.round()
		});
	}
	render () {
		return (
			<div className="input">
				<span>{this.props.label}</span>
				<input type="number" step={this.props.step} defaultValue={this.props.value}
					{ ...(( this.props.min !== undefined ) && { min: this.props.min })}
					{ ...(( this.props.max !== undefined ) && { max: this.props.max })}
					{ ...( this.props.disabled && { disabled: true })}
					onChange={(event) => {
						if (this.props.onChange) {
							this.props.onChange(event);
						}
						this.dispatch ();
					}}
					onBlur={() => {
						if (this.numberInput.current) {
							this.round(this.numberInput.current.value);
						}
					}}
					ref={this.numberInput}
				></input>
			</div>
		)
	}
	UNSAFE_componentWillReceiveProps (nextProps) {
		// will set the value directly instead of going through setState because reasons
		this.round(nextProps.value)
	}
}

export class OpacityInput extends Component {
	constructor (props) {
		super (props);
		this.rangeInput = React.createRef ();
		this.reactInput = React.createRef ();
	}
	render () {
		return (
			<>
				<input type="range" defaultValue={this.props.value} step="1" min="1" max="100" ref={this.rangeInput} onChange={(event) => {
					this.reactInput.current.round(event.target.value);
					this.reactInput.current.dispatch();
				}} />
				<Input label="%" value={this.props.value} step="1" min="1" max="100" event="opacity" disabled={this.props.disabled} ref={this.reactInput} onChange={(event) => {
					this.rangeInput.current.value = event.target.value;
				}} />
			</>
		)
	}
	UNSAFE_componentWillReceiveProps (nextProps) {
		// will set the value directly instead of going through setState because reasons
		if (this.rangeInput.current) {
			this.rangeInput.current.setAttribute('value', this.rangeInput.current.value = nextProps.value);
			this.reactInput.current.round(nextProps.value);
		}
	}
}