import dispatcher from '../../dispatcher'

import React, {Component} from 'react'
import './index.css'

import spinner from './spinner.png';

const defaultMessage = 'Please wait while your assets are being exported';

class PleaseWait extends Component {
	render () {
		return (
			<div className="pleasewait" style={{ display: 'none' }}>
				<div className="inner">{defaultMessage}
					<br />
					<br />
					<img src={spinner} alt="" />
				</div>
			</div>
		)
	}
}


// assuming there will be a single "please wait" screen

dispatcher.addEventListener ('hide pleasewait', function (event) {
	document.querySelector ('.studio .pleasewait').style.opacity = 0;
	setTimeout (function () {
		document.querySelector ('.studio .pleasewait').style.display = 'none';
		if (event.after) dispatcher.dispatchEvent (event.after);
	}, 2019);
});

dispatcher.addEventListener ('show pleasewait', function (event) {
	document.querySelector ('.studio .pleasewait').style.display = 'block';
	document.querySelector ('.studio .pleasewait').style.opacity = 1;
	document.querySelector ('.studio .pleasewait .inner').firstChild.textContent =
		event.message || defaultMessage;
});

export default PleaseWait
